<template lang="pug">
  .home
    HeroBlock
    EventsBlock
    ChooseBlock
    TrainersBlock
    LatestNews
    ContactsBlock
</template>

<script>
export default {
  name: 'Home',
  components: {
    HeroBlock: () => import('@/components/home/HeroBlock'),
    EventsBlock: () => import('@/components/home/EventsBlock'),
    ChooseBlock: () => import('@/components/home/ChooseBlock'),
    TrainersBlock: () => import('@/components/home/TrainersBlock'),
    LatestNews: () => import('@/components/home/LatestNews'),
    ContactsBlock: () => import('@/components/home/ContactsBlock')
  }
}
</script>
